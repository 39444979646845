.SelectInput .custom-select {
  padding: 0rem 1rem 0 0;
}

.SelectInput .MuiSelect-select {
  padding: 1rem;
}

.SelectInput.MuiListItem-root.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.08) !important;
}
