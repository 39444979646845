:root {
  --primary-color: #005683;
  --secondary-color: #46b2bb;
  --tertiary-color: #f7943c;
  --quartary-color: #403d39;
  --main-font-family: 'Raleway', sans-serif;
  --main-font-size: 1.375rem;
  --mobile-font-size: 0.875rem;
  --max-layout-width: 56.25rem;
}
.App {
  text-align: center;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-field-container {
  @apply flex flex-col font-bold relative text-base lg:text-2xl;
}

.input-field {
  @apply bg-background border-background rounded-2xl p-4 font-bold placeholder-faded text-base;
}

input:focus {
  @apply outline-none border-2 border-quartary;
}

.MuiInputBase-input {
  @apply text-base font-bold !important;
}

.MuiInputLabel-outlined {
  @apply font-bold text-faded !important;
}

.MuiInputLabel-outlined {
  font-family: 'Raleway', sans-serif !important;
}

.custom-select {
  @apply bg-background border-background rounded-2xl mt-2 md:mt-4 p-4 font-bold text-base md:text-2xl lg:text-3xl;
}

.custom-select > div {
  padding: 0;
}

.help-button {
  @apply inline-block cursor-pointer bg-secondary border-secondary rounded-full h-6 w-6 lg:h-8 lg:w-8 text-center text-white text-base lg:text-2xl ml-2 !important;
}

.survey-container {
  @apply container m-auto overflow-hidden pb-4;
}

.h-fit-content {
  height: fit-content;
}
