.address-input {
  border: none;
  border-bottom: 0.125rem solid var(--quartary-color);
  display: inline-block;
  background: none;
}

.address-select {
  border: none;
  border-bottom: 0.125rem solid var(--quartary-color);
  display: inline-block;
  background: none;
}

.address td {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
