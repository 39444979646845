.ContactBar {
  position: fixed;
  width: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--secondary-color);
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ContactBar a {
  cursor: pointer;
  background-color: transparent;
  flex: 1;
  margin: 12px;
  text-decoration: none;
  text-align: center;
  max-width: 60px;
  padding: none;
}

.ContactBar a > img {
  width: 100%;
}

.policy-review {
  color: #888;
  background-color: #ceeef0;
}

@media only screen and (max-width: 640px) {
  .ContactBar a > img {
    width: 80%;
  }
}
