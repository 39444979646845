.toggle-button {
  @apply font-bold py-2 md:px-20 !important;
}

.ToggleButton .MuiButton-outlined {
  @apply border-secondary border-2 !important;
}

@media (max-width: 768px) {
  .ToggleButton .MuiButtonGroup-grouped {
    @apply w-1/2;
  }
}

.MuiButtonGroup-root {
  @apply text-base;
}
