.react-tel-input .form-control {
  @apply bg-background border-background py-4 mb-2 rounded-2xl pl-16 placeholder-faded w-full h-full font-bold;
}

.react-tel-input {
  @apply pt-4;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  @apply hover:bg-secondary;
}
