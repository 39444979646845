.DatePicker .MuiInput-underline:before {
  @apply hidden;
}

.DatePicker .MuiInput-underline:after {
  @apply hidden;
}

.DatePicker .MuiFormControl-root {
  @apply p-4;
}

.DatePicker .MuiInputBase-input {
  @apply p-0;
}
