.payment-container {
  grid-area: payment;
}

.payment-details {
  max-height: 1.5rem;
}

@media only screen and (max-width: 640px) {
  .payment-container {
    grid-area: payment;
  }

  .payment-details {
    max-height: 25px;
  }
}
