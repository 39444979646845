/** Styling for WC Page **/
div[data-name='Amtrust_Officers'] .sv_q_erbox {
  font-size: 1.3rem;
}

div[data-name='Amtrust_Officers'] {
  max-width: 56.25rem !important;
  max-width: 900px !important;
  margin: 3rem auto;
  width: 100%;
}

tfoot::before {
  height: 3rem;
  display: table-row;
  content: '';
}

tfoot tr .sv_matrix_cell {
  border-top: 2px solid !important;
  font-weight: 600;
  padding-left: 10px;
}

div[data-name='employeeAddress'] {
  color: var(--secondary-color);
  font-weight: 600;
}

div[data-name='WC Officer Title'],
div[data-name='WC Employee Payroll Title'] {
  font-size: 1.5rem;
  text-align: left;
  max-width: 56.25rem;
  max-width: 900px !important;
  margin: 3rem auto;
  width: 100%;
}

div[data-name='WC Employee Payroll Title'] p {
  font-size: 1.1rem;
}

div[data-name='WC Officer Title'] {
  margin-bottom: -3rem;
}

div[data-name='WC Employee Payroll Title'] {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.sv_matrix_cell_actions .sv_matrix_dynamic_button {
  @apply mt-4 ml-4 px-1 md:px-3 py-1 flex justify-center items-center border-red-500 border-4 rounded-full text-red-500 font-medium uppercase;
}

.sv_matrix_cell_actions .sv_matrix_dynamic_button > span {
  @apply hidden md:block mr-1;
}

.sv_matrix_cell_actions .sv_matrix_dynamic_button::after {
  content: '';
  background-image: url('../../images/trash.svg');
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.sv_qstn {
  @apply overflow-hidden flex flex-col font-bold !important;
}

.sv_q_footer .sv_matrix_dynamic_button {
  @apply flex border-secondary border-4 rounded-full py-2 mt-4;
}

.sv_q_footer .sv-string-viewer {
  @apply text-secondary text-base px-3 font-semibold;
}

.sv_q_footer .sv-string-viewer::after {
  margin-left: 0.5rem;
  font-weight: 500;
  content: '+';
}

.sv_matrix_cell_actions {
  color: rgb(187, 187, 187) !important; /** Light Grey **/
}

.sv_p_title {
  @apply font-bold text-base lg:text-2xl;
  padding-left: 0rem !important;
}

.sv-paneldynamic__add-btn,
.sv_p_remove_btn {
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: var(--main-font-size);
  line-height: 1.15;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sv-paneldynamic__add-btn {
  background-color: var(--primary-color);
  padding: 0.5rem 2.2rem 0.5rem 2.2rem;
}

.sv_p_remove_btn {
  background-color: rgb(187, 187, 187);
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.sv_panel_dynamic > div:first-child {
  width: 100%;
}

.sv_panel_dynamic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  max-width: 56.25rem;
  max-width: 900px;
  margin: 3rem auto;
  width: 100%;
}

.sv_p_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sv_q_matrix_dynamic {
  @apply text-left w-full;
}

div[data-name='duties'],
div[data-name='Amtrust_EmployeePayrollMatrix_US_EN'] {
  overflow: hidden;
}

.sv_matrix_cell_header {
  text-decoration: none;
  font-weight: 600;
}

td[headers='%Ownership'] input,
td[headers='Est. Annual Compensation'] input,
td[headers='# of Employees'] input,
td[headers='Total Est. Wages'] input {
  height: 2.105em;
}

.sv_q_title {
  @apply font-bold text-base lg:text-2xl mb-2;
}

.sv_q_checkbox_control_label {
  @apply text-base;
}

.sv_matrix_cell {
  @apply text-base;
}

.sv_panel_dynamic .sv_p_container {
  text-align: left !important;
}

.sv_q_matrix_dynamic [class*='control'] {
  width: 22rem !important;
}

td[headers='# of Employees'] input,
td[headers='Total Est. Wages'] input {
  width: 9rem !important;
}

@media only screen and (max-width: 1080px) {
  .sv_q_matrix_dynamic [class*='control'] {
    width: 13rem !important;
  }

  td[headers='%Ownership'] input,
  td[headers='Est. Annual Compensation'] input,
  td[headers='# of Employees'] input,
  td[headers='Total Est. Wages'] input {
    width: 10rem !important;
  }
}

@media only screen and (max-width: 640px) {
  td[headers='%Ownership'] input,
  td[headers='Est. Annual Compensation'] input,
  td[headers='# of Employees'] input,
  td[headers='Total Est. Wages'] input {
    height: 2.35rem !important;
    width: 6rem !important;
  }

  div [name='alternativeWorkLocation'] input {
    width: 6rem !important;
  }

  div [name='alternativeWorkLocation'] input[placeholder='Street Name'] {
    width: 9.5rem !important;
  }

  .sv_p_wrapper {
    margin-right: 1.5rem;
  }

  .sv-paneldynamic__add-btn,
  .sv_p_remove_btn {
    align-self: center;
  }

  .sv_p_remove_btn {
    margin-left: 1.5rem;
  }

  div[data-name='WC Officer Title'],
  div[data-name='WC Employee Payroll Title'] {
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .sv_matrix_cell_header {
    font-size: 0.8rem;
  }

  td[headers='%Ownership'] input,
  td[headers='Est. Annual Compensation'] input,
  td[headers='# of Employees'] input,
  td[headers='Total Est. Wages'] input {
    height: 2.35rem !important;
    width: 3rem !important;
  }
}

/** General Survey Styling **/
@media (min-width: 768px) {
  .custom-description,
  .sv_p_container {
    width: 520px;
  }
}

@media (min-width: 1024px) {
  .custom-description,
  .sv_p_container {
    width: 896px;
  }
}

.custom-description,
.sv_p_container {
  @apply mx-3 md:mx-auto p-4 md:p-6 lg:p-8 rounded-lg text-base lg:text-2xl shadow-lg;
}

.custom-description {
  @apply bg-secondary text-white mb-8;
}

.sv_p_container {
  background-color: white;
}

.sv_q_required_text {
  display: none;
}

.sv_q_text_root {
  border-bottom: 0.1rem solid var(--primary-color);
}

.sv_qcbc.sv_qcbx {
  @apply border-solid border-4 border-faded rounded-lg;
}

.sv_q_matrix_dropdown {
  @apply w-full text-left;
  table-layout: fixed;
}

.sv_q_checkbox {
  @apply border-faded hover:bg-background;
}

.sv_q_checkbox:hover {
  @apply cursor-pointer;
}

.sv_q_checkbox:nth-child(2) {
  border-top-right-radius: 0.7rem;
  border-top-left-radius: 0.7rem;
}

.sv_q_checkbox:last-child {
  border-bottom-right-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
}

.sv_q_checkbox:not(:last-child) {
  @apply border-b-4;
}

.sv_q_checkbox_label {
  @apply p-4 h-full flex items-center gap-2;
}

.sv_q_checkbox_label:hover {
  @apply cursor-pointer;
}

.sv_q_checkbox_control_item {
  @apply w-5 h-5 appearance-none m-0 bg-white border-solid border-4 border-faded rounded-md p-0.5;
}

.sv_q_checkbox_control_item {
  min-width: 1.25rem;
}

.sv_q_checkbox_control_item:focus {
  @apply border-4 border-primary;
}

.sv_q_checkbox_control_item:checked {
  @apply bg-secondary border-0;
}

.sv_q_checkbox_control_item:checked:focus {
  @apply bg-secondary border-2 border-primary;
}

.sv_q_checkbox_control_item:checked::before {
  @apply flex items-center w-full h-full text-white text-lg;
  content: '✓';
}

.sv_q_checkbox_none,
.sv_q_checkbox_selectall {
  @apply font-bold;
}

.sv_q_checkbox_none,
.sv_q_checkbox_selectall {
  background-color: #c7eded;
}

.sv_q_radiogroup_control_item,
.sv_q_checkbox_control_item {
  margin-right: 0.2rem;
}

div[data-name='BusinessInformation_100_ProfessionsPercentage_WORLD_EN'] thead {
  @apply hidden;
}

div[data-name='BusinessInformation_100_ProfessionsPercentage_WORLD_EN']
  th:nth-child(2) {
  @apply text-center sm:text-left;
}

div[data-name='BusinessInformation_100_ProfessionsPercentage_WORLD_EN']
  tr
  td:nth-child(2) {
  @apply text-center sm:text-left;
}

div[data-name='BusinessInformation_100_ProfessionsPercentage_WORLD_EN'] input {
  @apply w-16 lg:w-20 bg-background border-background rounded-2xl p-4 mb-2 font-bold placeholder-faded text-base text-center;
}

@media only screen and (max-width: 640px) {
  .sv_nav {
    display: none !important;
  }

  .sv_q_matrix_dynamic [class*='control'] {
    width: 5rem !important;
  }
}
