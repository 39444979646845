.CheckBox .MuiSvgIcon-root {
  @apply w-12 h-12 md:w-16 md:h-16 !important;
}

.coverage-option-tips p {
  @apply mb-4;
}

.coverage-option-tips h3 {
  @apply text-xl font-bold mb-4;
}

.MuiIconButton-colorSecondary:hover {
  @apply hover:bg-gray-100;
}
