html,
body {
  color: #403d39;
}

.big-underline {
  text-decoration-color: #f7943c !important;
  text-decoration-thickness: 0.35rem !important;
  text-underline-offset: 0.45rem !important;
  text-decoration: underline;
}

.quote-box {
  display: grid;
  grid-template-areas:
    'coverage premium'
    'coverage button'
    'coverage summary'
    'coverage summary'
    'additional-insured summary'
    'additional-insured summary';
  column-gap: 2rem;
  row-gap: 0.75rem;
  padding: 0.625rem;
}

.quote-header {
  grid-area: quote-header;
}

@media only screen and (max-width: 1024px) {
  /* For general iPad and iPad pros layouts and mobile layouts */
  .big-underline {
    text-decoration: none;
  }

  .quote-box {
    grid-template-areas:
      'quote-header'
      'premium'
      'feinssn'
      'button'
      'payment'
      'summary'
      'coverage'
      'additional-insured';
    row-gap: 0.5rem;
  }
}

.error-bubble {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  color: white;
  font-weight: 400;
  line-height: 1.5;
  animation: 300ms cubic-bezier(0, 0.25, 0.25, 1) 0s 1 normal none running
    fpGBMM;
  position: relative;
  padding: 2rem 2.5rem;
  max-width: 56.25rem;
  margin: 3rem auto;
  max-width: 900px;
  margin: 3rem auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  font-size: var(--main-font-size);
  -webkit-font-smoothing: antialiased;
  border-radius: 1rem;
  transition: all 300ms ease-out 0s;
  background-color: var(--secondary-color);
}

.error-bubble::before {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  bottom: -0.75rem;
  transform: rotate(45deg);
  left: 2.5rem;
  background-color: var(--secondary-color);
}
