.modal {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal-content {
  background-color: #ceeef0;
  padding: 2.5rem;
  border: 0.125rem solid var(--secondary-color);
  text-align: center;
  margin-bottom: 6.5rem;
  border-radius: 1rem;
  max-width: 50%;
}

.loading {
  min-width: 50%;
  min-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content p {
  margin-bottom: 1.25rem;
}

/* The Close Button */
.close {
  color: var(--secondary-color);
  float: right;
  font-size: 1.75rem;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: gray;
  text-decoration: none;
  cursor: pointer;
}

.row-container {
  margin-bottom: 1rem;
}

.modal-input {
  text-align: left;
}

.input-row {
  position: relative;
}

.phone-container {
  width: 100%;
  background-color: white;
}

.input {
  width: 100%;
  padding-left: 15px;
  outline: none;
}

.date-picker {
  width: 100%;
  background: white;
  padding-left: 15px;
  padding-bottom: 5px;
}

.invalid-entry {
  color: #c62525 !important;
}

.invalid-row {
  border: 2px solid #c62525 !important;
}

@media only screen and (max-width: 900px) {
  .modal-content {
    max-width: 90vw;
    padding: 1rem;
    margin-top: 15rem;
    width: 90vw;
  }

  .modal {
    width: 100vw;
    padding-top: 10rem;
  }
}

@media only screen and (max-width: 640px) {
  .modal-content {
    margin-top: 25rem;
  }
}
