.bubble {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  color: white;
  font-weight: 400;
  line-height: 1.5;
  animation: 300ms cubic-bezier(0, 0.25, 0.25, 1) 0s 1 normal none running
    fpGBMM;
  position: relative;
  padding: 2rem 2.5rem;
  max-width: 56.5rem;
  margin: auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  font-size: var(--main-font-size);
  -webkit-font-smoothing: antialiased;
  border-radius: 1rem;
  transition: all 300ms ease-out 0s;
  background-color: var(--secondary-color);
}

.bubble::before {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  bottom: -0.75rem;
  transform: rotate(45deg);
  background-color: var(--secondary-color);
}
