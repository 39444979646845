.additional-insured-header-uncheck {
    border: 1px solid #878787;
    border-radius: 2.813rem 2.813rem 2.813rem 2.813rem;
}

.additional-insured-header-check {
    border: 1px solid #878787;
    border-radius: 2rem 2rem 0 0;
    border-bottom: 0;
}

.additional-insured-container {
    grid-area: additional-insured;
}
  
.additional-insured-innner-container {
    background-color: white;
    border: 1px solid #878787;
    border-radius: 0 0 2rem 2rem;
    max-width: 50rem;
    grid-area: additional-insured;
}