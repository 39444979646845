.SearchSelect .MuiInputBase-root {
  @apply bg-background rounded-2xl text-base;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.SearchSelect .MuiAutocomplete-endAdornment {
  @apply pr-1;
}

@media only screen and (max-width: 640px) {
  .MuiInputLabel-outlined {
    transform: translate(0.875rem, 0.7rem) scale(1) !important;
  }
}

@media (max-width: 640px) {
  .SearchSelect .MuiInputLabel-formControl {
    top: 20%;
  }
}

.SearchSelect .MuiInputLabel-shrink {
  @apply invisible;
}
