.QuotePageSelectInput .quote-page-custom-select {
  display: inline-block;
  border: none;
  border-radius: 0;
  border-bottom: 0.125rem solid var(--quartary-color);
}

.QuotePageSelectInput .quote-page-custom-select::before,
.QuotePageSelectInput .quote-page-custom-select::after {
  border: none !important;
}

.QuotePageSelectInput .quote-page-custom-select svg {
  fill: var(--primary-color);
}

@media only screen and (max-width: 640px) {
  .QuotePageSelectInput .MuiInputBase-input {
    padding: 0 !important;
  }
}
