.premium-container {
  position: relative;
  min-height: 12rem;
  text-align: center;
  background-color: #ceeef0;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  border-width: 0.2rem;
  border-color: var(--secondary-color);
  grid-area: premium;
}

@media only screen and (max-width: 640px) {
  .premium-container {
    width: 20.8rem;
    height: 10rem;
    padding: 0.5rem;
  }
  .premium-container img {
    width: 3rem !important;
    height: auto;
  }
}
