html,
body {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  color: '403d39';
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  background-color: #efefef;
}

input {
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}
