.customer-agreement-container {
    grid-area: coverage;
    max-width: 50rem;
}

.term-container {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    width: 100%;
}

.term-title {
    @apply sm:text-2xl text-xl;
    font-weight: bold;
}

.term-description {
    @apply sm:text-base text-xs;
    margin-top: 1rem;
}

.term-check {
    @apply text-base;
    margin-top: 1rem;
    border: 2px solid #878787;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    cursor: pointer;
}

.term-check * {
    cursor: inherit;
}

.term-check input[type="checkbox"] {    
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #878787;
    margin-right: 1rem;
}

.term-check input[type="checkbox"]:checked {    
    accent-color: #1EA2AE;
}

.term-check input[type="radio"] {
    width: 1.5rem;
    margin-right: 1rem;
}

.term-check input[type="radio"]:checked {
    accent-color: #1EA2AE;
}

.customer-agreement-container input[type="text"],.customer-agreement-container textarea {
    border-radius: 1rem;
    padding: 1rem;
    border: 2px solid #878787;
}

.send-signature {
    @apply bg-secondary;
}

.send-signature:disabled, .proceed-payment:disabled {
    @apply bg-gray-400;
    cursor: default;
}

.reset-input {
    @apply text-left;
    @apply text-secondary;
    @apply underline;
}

.return-foxden {
    @apply bg-white;
    color: #69D259;
}

.proceed-payment {
    @apply bg-tertiary;
}

.return-foxden:hover {
    background: #EEFFEC;
}