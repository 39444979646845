.coverages-header {
  border: 1px solid #878787;
  border-radius: 2rem 2rem 0 0;
  border-bottom: 0;
}

.coverages-inner-container {
  background-color: white;
  border: 1px solid #878787;
  border-radius: 0 0 2rem 2rem;
  max-width: 50rem;
  grid-area: coverage;
}

.coverages-container {
  grid-area: coverage;
}

.coverages-details {
  max-height: 27rem;
  padding-right: 1rem;
}

.coverage-description p {
  display: inline !important;
}

.limit .MuiInputBase-input {
  padding: 0;
}

.limit .MuiSvgIcon-root {
  fill: #1ea2ae !important;
}

.limit em {
  font-size: 18px !important;
}

.cancel-icon {
  transform: scale(2.2);
  color: #ff6766;
}

.coverage input[type='checkbox']:checked {
  background-color: var(--primary-color) !important;
}

.coverage-popup {
  max-height: 37.5rem;
  max-width: 33.333333%;
}

@media only screen and (max-width: 640px) {
  .coverage-popup {
    max-height: 31.25rem;
    max-width: 100% !important;
  }
  .coverages-container {
    margin: auto;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1280px) {
  /* For general iPad and iPad pros layouts and mobile layouts */
  .coverage-popup {
    max-width: 80% !important;
  }
  .coverages-container {
    margin: auto;
  }
}

::-webkit-scrollbar {
  margin-top: 0.75rem;
  width: 0.5rem;
  height: 0.625rem;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.75rem;
  background: #c2c9d2;
}
