.remove-button {
  @apply h-12 ml-2 px-1 md:px-3 py-1 flex justify-center items-center hover:bg-red-500 border-red-500 border-4 rounded-full text-red-500 hover:text-white font-medium;
}

.remove-button svg {
  fill: #ef4444;
  margin-left: 0.5rem;
}

.remove-button:hover svg {
  fill: white;
}

.add-button {
  @apply font-medium text-secondary hover:text-white border-secondary hover:bg-secondary border-4 rounded-full p-2;
}

.edit-icon {
  top: 2rem;
  right: 1rem;
}
