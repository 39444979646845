.confirm-pay-container {
  grid-area: button;
}

.confirm-buy:disabled {
  background-color: #6b7280 !important;
}


.policy-warning {
  position: relative;
  text-align: left;
  padding: 1rem;
  border-radius: 2rem;
  border-width: 0.2rem;
  border-color: #FFD64E;
  background-color: white;
  margin-bottom: 1rem;
}
